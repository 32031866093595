<template>
  <div class="about">
    <div class='box'>
      <div class='content'>
        <h1>About</h1>
        <p>Website by Ben Lewis</p>
        <p>Contact:</p>
        <p> BenLewis@skepticrobot.tech</p>
        <a href="mailto:benlewis@skepticrobot.tech">Email Me</a>
        <p><a href="https://gitlab.com/SkepticRobot"> Gitlab</a></p>
        <p><a href="https://github.com/Skeptic-Robot"> Github</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"About",
  data(){
    return{

    }
  },
  created(){
    document.title = "About";
  }
}
</script>